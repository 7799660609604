export const cityDirectionsMixin = {
    data() {
        return {
            loading: false,
            form: {
                fields: {
                    parent_id: null,
                    title: null,
                    preview_title: null,
                    attractions_title: null,
                    search_title: null,
                    slug: null,
                    image: null,
                    image_preview: null,
                    images_delete: null,
                    images_preview_delete: null,
                    description: null,
                    published: false,
                    order: 0,
                    is_start: false,
                    is_end: false,
                },
                attractions: [],
                seo: this.getDefaultSeo(),
            },
            attractionOptions: [],
            parentOptions: [],
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
        };
    },
    methods: {
        getDefaultSeo() {
            return [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
                { code: 'query', value: '' },
            ];
        },
    },
};
